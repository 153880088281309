import { faEnvelope, faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { checkValidPlatformInvite } from 'authentication/services/authentication';
import Loader from 'components/Loader';
import { useHistory } from 'react-router-dom';

export default function SignUpFormik({
  formFields,
  isAthleteOnly,
  setFormValues,
  handleSubmitAndVerify,
  tournamentId,
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const valid = useQuery(
    ['checkplatformValid', sessionStorage?.institution, tournamentId, sessionStorage?.otp],
    () =>
      checkValidPlatformInvite({
        institutionId: sessionStorage?.institution,
        tournamentId,
        otp: sessionStorage?.otp,
      }),
    {
      retry: false,
      enabled: !!sessionStorage?.institution && !!sessionStorage?.otp,
    }
  );

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Kindly Enter a Valid Email ID')
      .required('Kindly Enter a Valid Email ID'),
    password: Yup.string()
      .required('Please enter the password')
      .matches(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        'Password must contain 8 characters. Please include atleast one Uppercase character, one Lowercase character, a Digit and a Special character in your password.'
      ),
    role: Yup.string().required('I am is required'),
    athlete_full_name: Yup.string().when('role', {
      is: 'athlete',
      then: Yup.string()
        .min(2, 'Kindly Enter Your Full Name')
        .required('Kindly Enter Your Full Name')
        .test('is valid word', 'Kindly Enter Your Full Name', function (value) {
          if (value) {
            const temp = value.trim();
            return temp.length >= 2 && temp.split(' ').length >= 2;
          }
          return false;
        }),
    }),

    coach_full_name: Yup.string().when('role', {
      is: 'coach',
      then: Yup.string()
        .min(2, 'Kindly Enter Your Full Name')
        .required('Kindly Enter Your Full Name')
        .test('is valid word', 'Kindly Enter Your Full Name', function (value) {
          if (value) {
            const temp = value.trim();
            return temp.length >= 2 && temp.split(' ').length >= 2;
          }
        }),
    }),
    institution_full_name: Yup.string().when('role', {
      is: 'institution',
      then: Yup.string()
        .min(2, 'Kindly Enter Your Institution Name')
        .required('Kindly Enter Your Institution Name'),
    }),
    iAgree: Yup.bool().oneOf([true], 'Kindly Accept Terms & Conditions To Proceed'),
  });
  if (valid.isFetching) return <Loader />;
  if (valid.isFetched && valid?.data?.message === false) {
    return (
      <>
        <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
          The Platform Invite Link You Have Used Does Not Exist. Please Click The Button Below To
          Sign Up
          <br />
        </div>
        <button
          lang="en"
          className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
          onClick={() => {
            sessionStorage.clear();
            history.push('/signup');
          }}
        >
          Sign Up
        </button>
      </>
    );
  }
  return (
    <>
      {error ? (
        <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
          Please fix the following errors before submitting.
          <br />
          <span className="underline">{error?.error || error.message || error}</span>
        </div>
      ) : (
        ''
      )}
      <Formik
        initialValues={{
          athlete_full_name: '',
          coach_full_name: '',
          password: '',
          email: '',
          institution_full_name: '',
          iAgree: false,
          ...formFields,
          role: isAthleteOnly ? 'athlete' : formFields.role || '',
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => handleSubmitAndVerify({ data: values }, setError)}
      >
        {({ isSubmitting, values, setValues, errors, isValid }) => {
          return (
            <Form id="signUp-form">
              {!isAthleteOnly ? (
                <div
                  className="form-group has-feedback formio-component formio-component-radio formio-component-role  required"
                  onClick={(e) => {
                    if (e.target.value === values.role && e.target.checked)
                      setValues({ ...values, role: '' });
                  }}
                >
                  <label className="col-form-label  field-required">I am,</label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="flex flex-wrap ml-2"
                  >
                    <div className="mx-2 my-1 md:my-0">
                      <Field
                        type="radio"
                        name="role"
                        value="athlete"
                        className={`mr-2 form-check-input `}
                        onChange={(e) => {
                          setFormValues({ ...formFields, role: e.target.value });
                          setValues({ ...values, role: e.target.value });
                        }}
                      />
                      <label className="text-sm">Athlete (Student)</label>
                    </div>
                    <div className="mx-2 my-1 md:my-0">
                      <Field
                        type="radio"
                        name="role"
                        value="coach"
                        className="mr-2 form-check-input"
                        onChange={(e) => {
                          setFormValues({ ...formFields, role: e.target.value });
                          setValues({ ...values, role: e.target.value });
                        }}
                      />
                      <label className="text-sm">Coach</label>
                    </div>
                    <div className="mx-2 my-1 md:my-0">
                      <Field
                        type="radio"
                        name="role"
                        value="institution"
                        className="mr-2 form-check-input"
                        onChange={(e) => {
                          setFormValues({ ...formFields, role: e.target.value });
                          setValues({ ...values, role: e.target.value });
                        }}
                      />
                      <label className="text-sm">Institution</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="flex-1 text-red-600 text-sm mt-2"
                  />
                </div>
              ) : null}
              {values.role && values.role !== '' ? (
                <>
                  <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block field-required">
                    {roleText(values.role)[1]}
                  </label>
                  <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg overflow-hidden">
                    <Field
                      data-cy="signin"
                      type="text"
                      name={roleText(values.role)[0]}
                      placeholder={roleText(values.role)[1]}
                      className={`bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none`}
                    />
                  </div>
                  <ErrorMessage
                    name={roleText(values.role)[0]}
                    component="div"
                    className="flex-1 text-red-600 text-sm mt-2"
                  />
                </>
              ) : null}
              <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block mt-6 field-required">
                Email
              </label>
              <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                <div className="flex justify-center items-center pl-3 w-15">
                  <FontAwesomeIcon icon={faEnvelope} className="text-gray-450 " />
                </div>
                <Field
                  data-cy="signin"
                  type="text"
                  name="email"
                  placeholder="someone@domain.com"
                  className="bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="flex-1 text-red-600 text-sm mt-2"
              />
              <label className="font-semibold text-sm text-gray-650 mt-6  pb-2 md:pb-1 block field-required">
                Password
              </label>
              <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                <div className="flex justify-center items-center pl-3 w-15">
                  <FontAwesomeIcon icon={faLock} className="text-gray-450 " />
                </div>
                <Field
                  data-cy="password"
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="********"
                  className="flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                />
                <div className="flex justify-center items-center pr-3 w-15 ">
                  {passwordVisible ? (
                    <div
                      className="hover:bg-gray-100 px-2 py-1 rounded-full"
                      onClick={() => setPasswordVisible(false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} className=" text-gray-450 " />
                    </div>
                  ) : (
                    <div
                      className="hover:bg-gray-100 px-2 py-1 rounded-full"
                      onClick={() => setPasswordVisible(true)}
                    >
                      <FontAwesomeIcon icon={faEye} className=" text-gray-450 " />
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="flex-1 text-red-600 text-sm mt-2"
              />

              <label className="form-check-label mt-6">
                <Field lang="en" className="form-check-input" type="checkbox" name="iAgree" />
                <span className="field-required ">
                  I have read &amp; accept the{' '}
                  <a target="_blank" href="/static/SFAPLAY.COM-Terms-&amp;-Conditions.pdf">
                    Terms &amp; Conditions{' '}
                  </a>
                </span>
              </label>
              <ErrorMessage
                name="iAgree"
                component="div"
                className="flex-1 text-red-600 text-sm mt-2"
              />
              <button
                lang="en"
                className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
                type="submit"
                disabled={
                  (!values[roleText(values?.role)]?.length &&
                    !values?.email?.length &&
                    !values?.password?.length) ||
                  !isValid ||
                  isSubmitting
                }
                name="data[submit]"
              >
                Submit
                {isSubmitting ? <i className="fa fa-refresh fa-spin button-icon-right"></i> : null}
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function roleText(role) {
  if (role === 'athlete') {
    return ['athlete_full_name', 'Athlete (Student) Name'];
  } else if (role === 'coach') {
    return ['coach_full_name', 'Coach Name'];
  } else return ['institution_full_name', 'Institution Name'];
}
